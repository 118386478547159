import { Text, Badge, BadgeProps } from "@audi/audi-ui-react";
import styled from "styled-components";
import { StatusQueryOneGraphInfo } from "./App";
import { OneGraphServiceStatusEnum } from "./generated/onegraph";
import { statusToTexts } from "./utils";

const StyledList = styled.div`
  display: grid;
  width: 100%;
  margin-top: ${({ theme }) => `var(${theme.responsive.spacing.xl})`};
  gap: ${({ theme }) => `var(${theme.responsive.spacing.xs})`};
  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledOneGraphService = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `var(${theme.responsive.spacing.m})`};
  border: solid 1px;
  border-color: ${({ theme }) => `var(${theme.colors.base.grey["40"]})`};
  border-radius: 2px;
`;

// represents where the subGraph status info can be found into the oneGrpahInfo query
interface SubGraphDefinition {
  subGraphInfoKey?: keyof Omit<StatusQueryOneGraphInfo, "__typename">;
  name: string;
}

// list of available subGraphs, some of them are not integrated in the oneGraphInfo query
// thus they don't have a oneGraphInfo key. They are still defined here to show them in the status page
// with "warning" status, this way subgraph teams are aware of this missing functionaly for their subgraphs
export const subGraphs: SubGraphDefinition[] = [
  { subGraphInfoKey: "colaServiceInfo", name: "Configurator" },
  { subGraphInfoKey: "stockcarGraphqlInfo", name: "Stock Car" },
  { subGraphInfoKey: "financingGraphqlInfo", name: "Financing" },
  { name: "Audi Code" },
  { subGraphInfoKey: "carlineInfoServiceInfo", name: "Carline Info" },
  { subGraphInfoKey: "tedasServiceInfo", name: "Technical Data" },
];

interface SubGraphProps {
  oneGraphInfo: StatusQueryOneGraphInfo;
}

export function StatusSubGraphs({ oneGraphInfo }: SubGraphProps) {
  return (
    <StyledList>
      {subGraphs.map(({ name, subGraphInfoKey }) => {
        // default to WARN status in case the oneGraphInfo does not deliver status information for the
        // subgraph yet
        let subGraphStatus = OneGraphServiceStatusEnum.Warn;

        if (subGraphInfoKey) {
          const subGraphInfo = oneGraphInfo[subGraphInfoKey];
          subGraphStatus = subGraphInfo.health.status;
        }

        let variant: BadgeProps["variant"] = "notice";

        switch (subGraphStatus) {
          case "PASS":
            variant = "positive";
            break;
          case "FAIL":
            variant = "negative";
            break;
          default:
            variant = "notice";
            break;
        }

        return (
          <StyledOneGraphService key={name}>
            <div>
              <Text weight="bold">{name}</Text>
              <Text variant="copy2">
                {statusToTexts[subGraphStatus].subGraph}
              </Text>
            </div>
            <Badge variant={variant} spaceInlineStart="s" />
          </StyledOneGraphService>
        );
      })}
    </StyledList>
  );
}
