import { createOneGraphClient } from "@oneaudi/onegraph-client";

export const oneGraphClient = createOneGraphClient({
  endpoint:
    import.meta.env.VITE_ONEGRAPH_VARIANT === "production"
      ? import.meta.env.VITE_ONEGRAPH_PROD_URL
      : import.meta.env.VITE_ONEGRAPH_INT_URL,
  clientName: "onegraph-status",
  clientVersion: __APP_VERSION__,
  disableAPQ: import.meta.env.VITE_DISABLE_APOLLO_APQ === "true",
});
