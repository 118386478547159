import { OneGraphServiceStatusEnum } from "./generated/onegraph";

type StatusTextMap = {
  [Key in OneGraphServiceStatusEnum]: {
    summary: string;
    subGraph: string;
  };
};

export const statusToTexts: StatusTextMap = {
  FAIL: {
    summary: "Active Incident",
    subGraph: "Incident",
  },
  WARN: {
    // TODO: decided if WARN status is needed for the summary text
    summary: "Active Incident",
    subGraph: "Unknown status",
  },
  PASS: {
    summary: "All Systems Operational",
    subGraph: "Operational",
  },
};
