export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AudiCodeCar = AudiCodeError | ConfiguredCar | StockCar;

export type AudiCodeError = {
  __typename?: "AudiCodeError";
  code?: Maybe<AudiCodeErrorCarCode>;
  message?: Maybe<Scalars["String"]>;
};

export type AudiCodeErrorCarCode = CarCode & {
  __typename?: "AudiCodeErrorCarCode";
  country?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  language?: Maybe<Scalars["String"]>;
};

export type AudicodeGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: "AudicodeGraphqlInfo";
  endpoint: Scalars["String"];
  health: OneGraphServiceHealth;
  id: Scalars["ID"];
  meta: OneGraphServiceMetaInfo;
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars["String"];
};

/** information about stocks of a market */
export type AvailableStock = {
  __typename?: "AvailableStock";
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

/** Body type of a CarlineStructureCarline, has an ID and a name. */
export type BodyType = {
  __typename?: "BodyType";
  /** Machine readable body type id, e.g. 'limo'. */
  id: Scalars["String"];
  /** Localized human readable body type name, e.g. 'Limousine'. */
  name: Scalars["String"];
};

/** Cache Control scope */
export enum CacheControlScope {
  /**
   * If a cached response has a PRIVATE scope, its value is accessible by only a single user.
   * To enforce this restriction, the cache needs to know how to identify that user.
   * @see: https://www.apollographql.com/docs/apollo-server/performance/caching/#identifying-users-for-private-responses
   */
  Private = "PRIVATE",
  /**
   * By default, PUBLIC cached responses are accessible by all users.
   * @see https://www.apollographql.com/docs/apollo-server/performance/caching/#separating-responses-for-logged-in-and-logged-out-users
   */
  Public = "PUBLIC",
}

/** Generic Car, implemented as ConfiguredCar or StockCar */
export type Car = {
  /** Carline information of a car. */
  carline?: Maybe<CarlineBase>;
  /**
   * Unique identifier for a car.
   * Might contain AudiCode, MarketingCode etc.
   */
  code?: Maybe<CarCode>;
  /** Model information of a car. */
  model?: Maybe<CarModelBase>;
  /** Price information of a car. */
  prices?: Maybe<CarPrices>;
};

/**
 * Unique identifier for a car.
 * Might contain AudiCode, MarketingCode etc.
 */
export type CarCode = {
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars["String"]>;
  /**
   * Unique identifier for the stored car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars["String"];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars["String"]>;
};

export type CarCodeRepairInfo = {
  __typename?: "CarCodeRepairInfo";
  status: Scalars["Boolean"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type CarFeature = {
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars["String"];
  pr3: Scalars["ID"];
  pr7: Scalars["ID"];
  price?: Maybe<Price>;
};

/** Data object of the family of a feature */
export type CarFeatureFamily = {
  __typename?: "CarFeatureFamily";
  /** E.g. "Komfort.Spiegel" */
  id: Scalars["String"];
  /** E.g. "Mirrors" */
  name: Scalars["String"];
};

/** Data object of the feature group of a feature */
export type CarFeatureGroup = {
  __typename?: "CarFeatureGroup";
  /** E.g. 'Komfort' */
  id: Scalars["String"];
  /** E.g. 'Comfort' */
  name: Scalars["String"];
};

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type CarFeatureImage = {
  /** Size - width: 140, height: 63 */
  ak4DetailsL?: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS?: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL?: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile?: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile?: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile?: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL?: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage?: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD?: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD?: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL?: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv?: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage?: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage?: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS?: Maybe<ImageSource>;
};

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type CarFeatureText = {
  akHeadline?: Maybe<Scalars["String"]>;
  akRemarks?: Maybe<Scalars["String"]>;
  akText?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<Maybe<Scalars["String"]>>>;
  carpetText?: Maybe<Scalars["String"]>;
  carpetTitle?: Maybe<Scalars["String"]>;
  dashboardText?: Maybe<Scalars["String"]>;
  dashboardTitle?: Maybe<Scalars["String"]>;
  engineDescription?: Maybe<Scalars["String"]>;
  engineGear?: Maybe<Scalars["String"]>;
  engineName?: Maybe<Scalars["String"]>;
  engineNamesText?: Maybe<Scalars["String"]>;
  exteriorColorText?: Maybe<Scalars["String"]>;
  exteriorColorTitle?: Maybe<Scalars["String"]>;
  exteriorContrastColorText?: Maybe<Scalars["String"]>;
  exteriorContrastColorTitle?: Maybe<Scalars["String"]>;
  headliningText?: Maybe<Scalars["String"]>;
  headliningTitle?: Maybe<Scalars["String"]>;
  intro?: Maybe<Scalars["String"]>;
  seatsText?: Maybe<Scalars["String"]>;
  seatsTitle?: Maybe<Scalars["String"]>;
  teaserText?: Maybe<Scalars["String"]>;
};

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type CarFeatureVideo = {
  /** Size - width: 888, height: 500 */
  bigVideoMp4?: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4?: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb?: Maybe<VideoSource>;
};

/** Identifier of a car. Can be used as Input value for specific queries. */
export type CarIdentifier = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /**
   * User selected features/options contained in the ConfiguredCar
   * 7-digit or 3-digit pr numbers
   */
  equipmentOptions?: Maybe<Array<Scalars["String"]>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars["String"]>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars["String"]>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
  /** The model input separated in an own input */
  model: CarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars["String"];
};

/** Images for a car. */
export type CarImages = {
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
};

/** Model information */
export type CarModelBase = {
  /** Identifier of the model */
  id?: Maybe<CarModelIdentifier>;
  /** Name of the model */
  name?: Maybe<Scalars["String"]>;
};

/**
 * Interface for a model of a car.
 * The values has to be handled as 'one' - they can't be modified or changed in an authoring environment.
 * The fo
 */
export type CarModelIdentifier = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars["String"];
  /**
   * 3-digit pr numbers.
   * The extensions are PrNumbers that are part of the model code.
   * These PrNumbers are treated as standard options for this model (e.g. set a specific package or equipment).
   * This extensions can't be deselected in the configuration process. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model.
   * The handling of extensions can differ in different markets.
   */
  extensions?: Maybe<Array<Scalars["String"]>>;
  /** 1-digit model version */
  version: Scalars["Int"];
  /** The year of the catalogue release */
  year: Scalars["Int"];
};

/** A specific price. */
export type CarPrice = {
  /** Market specific currency for the price */
  currencyDetails?: Maybe<Currency>;
  /** Formatted price with currency (e.g. 1.321,50 EUR) */
  formattedValue?: Maybe<Scalars["String"]>;
  /** Raw value of the price (e.g. 1321.5) */
  value: Scalars["Float"];
  /** Formatted price without currency (e.g. 1.321,50) */
  valueAsText?: Maybe<Scalars["String"]>;
};

/** All available prices for a car. */
export type CarPrices = {
  /** Total price of the car */
  total?: Maybe<CarPrice>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Carline = CarlineBase & {
  __typename?: "Carline";
  id: Scalars["String"];
  name: Scalars["String"];
};

/** Carline custom attributes. */
export type CarlineAttribute = {
  __typename?: "CarlineAttribute";
  /** Machine readable unique identifier for a single attribute, e.g. 'umweltbonus'. */
  id: Scalars["String"];
  /** Localized human readable display name, e.g. 'Elektro Förderung'. */
  name: Scalars["String"];
};

/** information about a carline in the stocks of a market */
export type CarlineAvailableStock = {
  __typename?: "CarlineAvailableStock";
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

/** Carline information */
export type CarlineBase = {
  /** Id of the carline (a1sb)  */
  id: Scalars["String"];
  /** Localized name of the carline (A1 Sportback)  */
  name: Scalars["String"];
};

/** Consumption and emission strings for this carline. */
export type CarlineConsumptionAndEmission = {
  __typename?: "CarlineConsumptionAndEmission";
  /** Raw consumption and emission ranges. */
  ranges?: Maybe<CarlineConsumptionAndEmissionRanges>;
  /** Summary of formatted consumption and emission strings. */
  summary?: Maybe<CarlineConsumptionAndEmissionSummary>;
};

/** Consumption and emission range data for a carline. */
export type CarlineConsumptionAndEmissionData = {
  __typename?: "CarlineConsumptionAndEmissionData";
  /** Emission range data. */
  co2Emission?: Maybe<CarlineMinMaxValue>;
  /** Consumption range data. */
  consumption?: Maybe<CarlineMinMaxValue>;
  /** Power consumption range data. */
  powerConsumption?: Maybe<CarlineMinMaxValue>;
};

/** Contains raw values for consumption and emission data ranges. */
export type CarlineConsumptionAndEmissionRanges = {
  __typename?: "CarlineConsumptionAndEmissionRanges";
  /** Unit used for displaying consumption values, e.g. 'l/100km' */
  consumptionUnit: Scalars["String"];
  /** Unit used for displaying emission values, e.g. 'g/100km' */
  emissionUnit: Scalars["String"];
  /** Uniquely identifies a fuel type, e.g. 'diesel' */
  fuelTypeId: Scalars["String"];
  /** NEDC emission and consumption data. */
  nedc?: Maybe<CarlineConsumptionAndEmissionData>;
  /** WLTP emission and consumption data. */
  wltp?: Maybe<CarlineConsumptionAndEmissionData>;
};

/**
 * Collection of formatted consumption and emission strings for this carline.
 * Ths values will contain references to footnotes that are listed in a separate field.
 * This is a convenient type for handling consumption & emission in the front end. No formatting is required.
 * The type 'TechnicalDataRanges' can be used for detailed information of the particular ranges and values.
 */
export type CarlineConsumptionAndEmissionSummary = {
  __typename?: "CarlineConsumptionAndEmissionSummary";
  /**
   * Consumption in the correct formatted values in respect to country, language.
   * eg. "Stromverbrauch kombiniert<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 26.1-21.7 kWh&#x2F;100 km"
   */
  consumption: Scalars["String"];
  /**
   * Emission in the correct formatted values in respect to country, language.
   * eg.  "CO<sub>2</sub>-Emissionen<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 0-0 g&#x2F;km"
   */
  emissionCO2: Scalars["String"];
  /**
   * List of footnote reference within the fields "consumption" and "emission"
   * eg.["footnote.reference.cae_global_disclaimer"]
   * }
   */
  footnoteReference?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroup = {
  __typename?: "CarlineGroup";
  /** Ordered list of carlines. The exact order is determined in the carline management UI. */
  carlines: Array<CarlineStructureCarline>;
  /** Optional exterior views. */
  exteriorViews?: Maybe<ExteriorViews>;
  /** Unique (within a carline structure), machine readable carline group id, e.g. 'a3' */
  id: Scalars["String"];
  /** Indicates whether or not this carline group was mocked in the carline management UI, e.g. 'false' */
  isMocked: Scalars["Boolean"];
  /** Carline editor metadata. */
  metadata: CarlineGroupMetadata;
  /** Localized, human readable display name, e.g. 'A3' */
  name: Scalars["String"];
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroupCarlinesArgs = {
  country?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
};

/** Input type to uniquely identify a carline group. */
export type CarlineGroupIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars["String"]>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars["String"];
  /** Machine readable carline group id, e.g. 'a1' */
  id: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars["String"];
  targetGroup?: InputMaybe<Scalars["String"]>;
};

/** Describes carline group metadata for the carline editor. */
export type CarlineGroupMetadata = {
  __typename?: "CarlineGroupMetadata";
  /** Visibility flag of this carline group. */
  isActive: Scalars["Boolean"];
  /** List of previous carline group IDs. */
  previousIds: Array<Maybe<Scalars["String"]>>;
  /** The carline group sort order. */
  sortOrder?: Maybe<Scalars["Int"]>;
};

export type CarlineGroupsIdentifierInput = {
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars["String"];
};

/** Contains Health Status */
export type CarlineHealth = {
  __typename?: "CarlineHealth";
  code: Scalars["String"];
  message: Scalars["String"];
  status: Scalars["String"];
};

/** Uniquely identifies a carline. */
export type CarlineIdentifier = {
  __typename?: "CarlineIdentifier";
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
};

/** Input type to uniquely identify a carline. */
export type CarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars["String"]>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars["String"];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars["String"];
  /** Unique, Target group identifier (currently not used) */
  targetGroup?: InputMaybe<Scalars["String"]>;
};

/** Contains status for carline service */
export type CarlineInfoServiceInfo = OneGraphSubServiceInfo & {
  __typename?: "CarlineInfoServiceInfo";
  endpoint: Scalars["String"];
  health: OneGraphServiceHealth;
  id: Scalars["ID"];
  meta: OneGraphServiceMetaInfo;
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars["String"];
};

/** Single Market Item */
export type CarlineMarket = {
  __typename?: "CarlineMarket";
  /** Country code of market e.g. de */
  countryCode: Scalars["String"];
  /** Market id e.g. 50000 */
  id: Scalars["String"];
  /** All availbale languages for this market as array, e.g. [de, en] */
  languages: Array<Scalars["String"]>;
  /** Market name e.g. Germany */
  marketName: Scalars["String"];
};

/** Contains all available Markets */
export type CarlineMarkets = {
  __typename?: "CarlineMarkets";
  markets?: Maybe<Array<Maybe<CarlineMarket>>>;
};

/** Describes carline metadata for the carline editor. */
export type CarlineMetadata = {
  __typename?: "CarlineMetadata";
  /** Visibility flag of this carline. */
  isActive: Scalars["Boolean"];
  /** List of previous carline IDs. */
  previousIds: Array<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["String"]>;
  /** The carline sort order. */
  sortOrder: Scalars["Int"];
};

/** Upper and lower boundary of a value range. */
export type CarlineMinMaxValue = {
  __typename?: "CarlineMinMaxValue";
  /** Upper range boundary. */
  max?: Maybe<Scalars["Float"]>;
  /** Lower range boundary. */
  min?: Maybe<Scalars["Float"]>;
};

/** Describes a generic carline price. */
export type CarlinePrice = {
  __typename?: "CarlinePrice";
  /** Currency code according to ISO 4217. */
  code: Scalars["String"];
  /** Currency symbol as string. */
  symbol?: Maybe<Scalars["String"]>;
  /** Raw value. */
  value: Scalars["Float"];
  /** Formatted value according to country / language. */
  valueAsText?: Maybe<Scalars["String"]>;
};

/** Contains status for carline service */
export type CarlineStatus = {
  __typename?: "CarlineStatus";
  endpoint: Scalars["String"];
  health: CarlineHealth;
  id: Scalars["String"];
  meta: CarlineStatusMeta;
  name: Scalars["String"];
  version: Scalars["String"];
};

/** Carline status meta information */
export type CarlineStatusMeta = {
  __typename?: "CarlineStatusMeta";
  channel: Scalars["String"];
  homepage: Scalars["String"];
  issueTracker: Scalars["String"];
  team: Scalars["String"];
};

/** Describes the carline structure for a specific brand, country, language and target group. */
export type CarlineStructure = {
  __typename?: "CarlineStructure";
  /** Carline attributes available in this market. */
  carlineAttributes: Array<CarlineStructureAttribute>;
  /** Ordered list of carline groups. The exact order is determined in the carline management UI. */
  carlineGroups: Array<CarlineGroup>;
  /** The currently selected mode of database operation. */
  mode: CarlineStructureMode;
};

/** Contains translations for carline attributes. */
export type CarlineStructureAttribute = {
  __typename?: "CarlineStructureAttribute";
  /** Uniquely identifies a carline attribute, e.g. 'umweltbonus'. */
  id: Scalars["String"];
  /** List of carline attribute name translations. */
  names: Array<CarlineStructureAttributeName>;
};

/** Contains a localized value for the specified language. */
export type CarlineStructureAttributeName = {
  __typename?: "CarlineStructureAttributeName";
  /** Specified ISO two letter language code. e.g. 'de'. */
  language: Scalars["String"];
  /** Localized value, e.g. 'Erdgasantrieb'. */
  value: Scalars["String"];
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarline = {
  __typename?: "CarlineStructureCarline";
  /** List of carline body types and custom attributes. */
  attributes: Array<CarlineAttribute>;
  /**
   * information about the carline in the stocks of the market.
   * the market is selected by the information in the `CarlineIdentifier`
   */
  availableStock?: Maybe<CarlineAvailableStock>;
  /** Body type of this carline. */
  bodyType?: Maybe<BodyType>;
  /** Summary of consumption and emission ranges formatted as string according to country and language. */
  consumptionAndEmission?: Maybe<CarlineConsumptionAndEmission>;
  /** Optional exterior views. */
  exteriorViews?: Maybe<ExteriorViews>;
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars["String"];
  /** Unique carline identifier */
  identifier: CarlineIdentifier;
  /** Indicates whether or not this carline was mocked in the carline management UI, e.g. 'false' */
  isMocked: Scalars["Boolean"];
  /** Carline editor metadata. */
  metadata: CarlineMetadata;
  /** Lowest possible price of this carline. */
  minPrice?: Maybe<CarlinePrice>;
  /** The year of the catalogue release, e.g. '2021' */
  modelYear: Scalars["Int"];
  /** Localized, human readable display name, e.g. 'A3 Sportback' */
  name: Scalars["String"];
  /** Trimlines of this carline. */
  trimlines?: Maybe<Array<Maybe<CarlineTrimline>>>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarlineAvailableStockArgs = {
  partnerInfo?: InputMaybe<PartnerInfo>;
};

/** Input type to uniquely identify a carline for carline structure requests. */
export type CarlineStructureCarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars["String"]>;
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  carlineId?: InputMaybe<Scalars["String"]>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars["String"];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id?: InputMaybe<Scalars["String"]>;
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars["String"];
};

export type CarlineStructureIdentifierInput = {
  /** KVPS brand id. e.g. A for Audi */
  brand?: InputMaybe<Scalars["String"]>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars["String"];
  /** 5-digit target group */
  targetGroup?: InputMaybe<Scalars["String"]>;
};

/** Determines the order of fetch and merge operations between API and database. */
export enum CarlineStructureMode {
  /** Fetch carline structure from the API and merge preview carlines and carline groups from the database. */
  Automatic = "AUTOMATIC",
  /** Fetch carline structure from the database and fill in details from the API. */
  Manual = "MANUAL",
}

/** All Data regarding the trimline */
export type CarlineTrimline = {
  __typename?: "CarlineTrimline";
  description?: Maybe<Scalars["String"]>;
  headline?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  media?: Maybe<Array<Maybe<CarlineTrimlineMedia>>>;
  models?: Maybe<Array<Maybe<CarlineTrimlineModel>>>;
  name?: Maybe<Scalars["String"]>;
  teaser_text?: Maybe<Scalars["String"]>;
};

/** Trimline Media Data */
export type CarlineTrimlineMedia = {
  __typename?: "CarlineTrimlineMedia";
  height?: Maybe<Scalars["Int"]>;
  mimeType?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
};

/** Trimline Model Data */
export type CarlineTrimlineModel = {
  __typename?: "CarlineTrimlineModel";
  engineName?: Maybe<Scalars["String"]>;
  gearType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  trimlineName?: Maybe<Scalars["String"]>;
};

/** @deprecated change mutation not implemented */
export type ChangeAction = {
  itemId: Scalars["String"];
  resolveConflicts?: InputMaybe<Scalars["Boolean"]>;
  type: ChangeType;
};

/** The input type for the query changeConfiguredCarFeature. */
export type ChangeConfiguredCarFeatureInput = {
  /** An action that defines if you want to remove or to add the given feature. */
  action: FeatureChangeAction;
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts?: InputMaybe<Scalars["Boolean"]>;
  /** The feature id (pr3) that you want to add/remove to the configuration. */
  feature: Scalars["ID"];
  /** The configured car you want to change */
  id: ConfiguredCarInput;
};

/**
 * The input type for the query changeConfiguredCarModel.
 *
 * In contrast to a feature change you can only set a new model and not remove a model in the configuration.
 */
export type ChangeConfiguredCarModelInput = {
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts?: InputMaybe<Scalars["Boolean"]>;
  /** The configured car you want to change */
  id: ConfiguredCarInput;
  /** The model that you want to set to the configuration. */
  model: ConfiguredCarModelInput;
};

/** The return type for the query changeConfiguredCarModel and changeConfiguredCarFeature. */
export type ChangeConfiguredCarPayload = {
  __typename?: "ChangeConfiguredCarPayload";
  /**
   * A configured car, if the configuration could be changed successfully. `null` if a
   * conflict occured while changing the configuration and `autoResolveConflict` was set to `false`.
   */
  configuredCar?: Maybe<ConfiguredCar>;
  /**
   * Contains a conflict, if a conflict occured while changing the configuration. If `autoResolveConflict` was set to
   * `true` this field contains that conflict that was automatically resolved.
   */
  conflict?: Maybe<ConfiguredCarConflict>;
  /**
   * A list of side effects (without user choice) that
   * 1. occured while changing the configuration, if configuredCar is not null.
   * 2. will occur, if configuredCar is null and when accepting the conflict in `conflict`.
   */
  sideEffects: Array<SolutionSideEffect>;
};

/** @deprecated change mutation not implemented */
export enum ChangeType {
  Add = "ADD",
  Remove = "REMOVE",
}

/** Returned payload containing ConfiguredCar and conflict resolution information as a result of a configuration change. */
export type ChangedConfiguredCar = {
  __typename?: "ChangedConfiguredCar";
  autoResolvedConflict: Scalars["Boolean"];
  configuredCar: ConfiguredCar;
};

/** Implementation of the subgraph service info object. Returns status info for the cola subgraph */
export type ColaServiceInfo = OneGraphSubServiceInfo & {
  __typename?: "ColaServiceInfo";
  endpoint: Scalars["String"];
  health: OneGraphServiceHealth;
  id: Scalars["ID"];
  meta: OneGraphServiceMetaInfo;
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Configuration = {
  __typename?: "Configuration";
  availableOptions?: Maybe<Array<Option>>;
  ave: Scalars["String"];
  carline: Carline;
  emissionAndConsumption?: Maybe<EmissionAndConsumption>;
  engine: Engine;
  prString: Scalars["String"];
  priceDate: PriceDate;
  prices: Prices;
  renderImages?: Maybe<RenderImages>;
  selectedOptions?: Maybe<Array<Option>>;
  /** @deprecated No longer supported */
  techData: TechData;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationAvailableOptionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationPricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationSelectedOptionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar.catalog.features` */
export type ConfigurationFeature = CarFeature & {
  __typename?: "ConfigurationFeature";
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars["String"];
  pr3: Scalars["ID"];
  pr7: Scalars["ID"];
  price?: Maybe<Price>;
};

/** @deprecated queries deprecated */
export type ConfigurationIdentifier = {
  identifier: Scalars["String"];
  type: IdentifierType;
};

/** @deprecated data moved into `ConfiguredCar.catalog.packages` */
export type ConfigurationPackage = CarFeature & {
  __typename?: "ConfigurationPackage";
  content: Array<ConfigurationFeature>;
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars["String"];
  pr3: Scalars["ID"];
  pr7: Scalars["ID"];
  price?: Maybe<Price>;
};

/** A fully configured car */
export type ConfiguredCar = Car & {
  __typename?: "ConfiguredCar";
  /** The id and name of the carline of the configured car */
  carline?: Maybe<ConfiguredCarCarline>;
  /** The carline object of the configured car */
  carlineStructureCarline?: Maybe<CarlineStructureCarline>;
  /** The complete catalog with all available features, model entries and package details. */
  catalog?: Maybe<ConfiguredCarCatalog>;
  /** configured cars are not saved cars, so the code is always empty. */
  code: ConfiguredCarCarCode;
  /** Contains data about the outside color of the car and hints for the brightness of overlapping text */
  exteriorColor: ConfiguredCarColor;
  /** The "id" attribute is still experimental. Do not use it in production. */
  id: ConfiguredCarIdentifier;
  /** Contains EEC labels and other media resources of the configured car */
  media?: Maybe<ConfiguredCarMediaResources>;
  /** Object which describes the car */
  model?: Maybe<ConfiguredCarModelBase>;
  prices?: Maybe<ConfiguredCarPrices>;
  technicalData?: Maybe<ConfiguredCarTechnicalData>;
};

/** A fully configured car */
export type ConfiguredCarPricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** media type Audi Visualization Engine (AVE 3d renderengine) */
export type ConfiguredCarAve = {
  __typename?: "ConfiguredCarAve";
  /** special AVE string containig all prStrings in a special form */
  renderString?: Maybe<Scalars["String"]>;
};

/** Input type to uniquely identify a carline. */
export type ConfiguredCarByCarlineInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Unique, machine readable carline id, e.g. `a3sb` */
  carlineId: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
};

export type ConfiguredCarCarCode = CarCode & {
  __typename?: "ConfiguredCarCarCode";
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language?: Maybe<Scalars["String"]>;
  repairInfo?: Maybe<CarCodeRepairInfo>;
  urls?: Maybe<ConfiguredCarCarCodeUrls>;
};

export type ConfiguredCarCarCodeUrls = {
  __typename?: "ConfiguredCarCarCodeUrls";
  entry?: Maybe<Scalars["String"]>;
  pdf?: Maybe<Scalars["String"]>;
};

/** The carline of the configured car */
export type ConfiguredCarCarline = CarlineBase & {
  __typename?: "ConfiguredCarCarline";
  /** e.g. 'a4limo' */
  id: Scalars["String"];
  /** e.g. 'A4 Limousine' */
  name: Scalars["String"];
};

export type ConfiguredCarCatalog = {
  __typename?: "ConfiguredCarCatalog";
  /** All features available for the configured car. */
  features: ConfiguredCarFeatures;
  /** All engine specific model entries of the catalog. */
  models?: Maybe<ConfiguredCarCatalogModels>;
  /** @deprecated ADR: prefer object return types over arrays. */
  packages?: Maybe<Array<ConfigurationPackage>>;
  /** All packages available for the configured car. A package may contain several features. */
  packagesDetails: ConfiguredCarPackages;
};

export type ConfiguredCarCatalogModel = {
  __typename?: "ConfiguredCarCatalogModel";
  /** The driveName of this model. e.g. "Front-wheel drive" */
  driveName?: Maybe<Scalars["String"]>;
  /** The engineName of this model. e.g. "35 TFSI" */
  engineName?: Maybe<Scalars["String"]>;
  /** The fullName of this model. e.g. "Sport 35 TFSI 6-speed" */
  fullName?: Maybe<Scalars["String"]>;
  /** The gearName of this model. e.g. "6-speed" */
  gearName?: Maybe<Scalars["String"]>;
  /** The gearType of this model. e.g. "Manual" */
  gearType?: Maybe<Scalars["String"]>;
  /** id of the model in the format "${audicode - 6 digits}${version - 1 digit}_${modelyear}". e.G. "8WCA7G0_2023" */
  id?: Maybe<Scalars["String"]>;
  identifier?: Maybe<ConfiguredCarModelIdentifier>;
  /** The price for the selected model */
  price?: Maybe<ConfiguredCarCatalogModelPrice>;
  /** The status flags for this model */
  status?: Maybe<Status>;
  /** The trimline id of the model. e.g. "trimline_default" */
  trimlineId?: Maybe<Scalars["String"]>;
};

export type ConfiguredCarCatalogModelPrice = CarPrice & {
  __typename?: "ConfiguredCarCatalogModelPrice";
  /** Details of the currency of the price */
  currencyDetails?: Maybe<Currency>;
  /** The formatted value as text. e.g. '39.100,00 EUR' */
  formattedValue?: Maybe<Scalars["String"]>;
  /** The value. e.g. 39100 */
  value: Scalars["Float"];
  /** The value as text. e.g. '39.100,00' */
  valueAsText?: Maybe<Scalars["String"]>;
};

/** Wrapper class for ConfiguredCarCatalogModel */
export type ConfiguredCarCatalogModels = {
  __typename?: "ConfiguredCarCatalogModels";
  data?: Maybe<Array<Maybe<ConfiguredCarCatalogModel>>>;
};

/** Contains data about a color of the car and hints about the brightness of overlapping texts */
export type ConfiguredCarColor = {
  __typename?: "ConfiguredCarColor";
  /** The audi code of the color */
  code: Scalars["String"];
  /** A hint about which color texts must have if they overlap the cars color. (possible values: 'LIGHT', 'DARK') */
  contrastThemeHint: ContrastThemeHint;
  /** The hex representation of the color. */
  hexColor: Scalars["String"];
};

/** The type to present a conflict while changinging the configuration. */
export type ConfiguredCarConflict = {
  __typename?: "ConfiguredCarConflict";
  /** how much does it cost to solve the conflict with the pre-selected solutions */
  price: Price;
  /** groups of solutions containing alternative features that should be added or removed to solve the conflict */
  solutionGroups: Array<SolutionGroups>;
  /** A reference to a feature or model that triggered the configuration conflict. It can be used to show the name and the prices of the item that triggered this conflict. */
  trigger: ConfiguredCarFeature;
  /** A reference to the original trigger. This value has to be supplied to conflict change and accept queries. */
  triggerId: Scalars["ID"];
};

/** Contains media urls related to EEC (Energy Efficiency Certificate) labels. */
export type ConfiguredCarEecLabelImageUrls = {
  __typename?: "ConfiguredCarEECLabelImageUrls";
  /** An url pointing to the original sized EEC label for this configuration. */
  eecLabelImageUrl?: Maybe<Scalars["String"]>;
  /**
   * An url pointing to a smaller sized EEC label for this configuration, if available.
   * E.g. SVG typed images do not need a smaller scaled version.
   */
  eecLabelImageUrlSmall?: Maybe<Scalars["String"]>;
};

export type ConfiguredCarFeature = ConfiguredCarFeatureInterface & {
  __typename?: "ConfiguredCarFeature";
  /** Family of the package */
  family: CarFeatureFamily;
  /** Feature group mapping */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  financingParameters?: Maybe<FinancingParameters>;
  /** The feature group of the package */
  group: CarFeatureGroup;
  images?: Maybe<ConfiguredCarFeatureImage>;
  /** e.g. 'Innenspiegel abblendbar' */
  name: Scalars["String"];
  /** e.g. '4L2' */
  pr3: Scalars["ID"];
  /** e.g. 'MIRS4L2' */
  pr7: Scalars["ID"];
  /** The price of the feature */
  price?: Maybe<Price>;
  rate?: Maybe<PartRateResult>;
  /** The status of the feature */
  status: Status;
  /** Marketing text blocks */
  texts?: Maybe<ConfiguredCarFeatureText>;
  /** Links to Labels and PDF infos with data if the feature is from type tyre. Feature group 'RIMS' */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

/** Possible groups or categories to which a catalog feature could belong to */
export enum ConfiguredCarFeatureGroup {
  Accessories = "ACCESSORIES",
  ExteriorColors = "EXTERIOR_COLORS",
  InteriorTiles = "INTERIOR_TILES",
  Lights = "LIGHTS",
  Rims = "RIMS",
  Seats = "SEATS",
  SteeringWheels = "STEERING_WHEELS",
}

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type ConfiguredCarFeatureImage = CarFeatureImage & {
  __typename?: "ConfiguredCarFeatureImage";
  /** Size - width: 140, height: 63 */
  ak4DetailsL?: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS?: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL?: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile?: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile?: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile?: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL?: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage?: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD?: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD?: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL?: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv?: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage?: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage?: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS?: Maybe<ImageSource>;
};

/** Data of a single feature of a configured car */
export type ConfiguredCarFeatureInterface = {
  /** The family of a feature */
  family: CarFeatureFamily;
  /** Feature group mapping */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  financingParameters?: Maybe<FinancingParameters>;
  /** The feature group of the feature */
  group: CarFeatureGroup;
  images?: Maybe<ConfiguredCarFeatureImage>;
  /** E.g. 'Auto-dimming rearview mirror' */
  name: Scalars["String"];
  /** E.g. '4L2' */
  pr3: Scalars["ID"];
  /** E.g. 'MIRS4L2' */
  pr7: Scalars["ID"];
  /** The price of the feature */
  price?: Maybe<Price>;
  /** The status of the feature */
  status: Status;
  texts?: Maybe<ConfiguredCarFeatureText>;
  /** Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'. */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

/** configuration states to the corresponding bitflags from the DPU (00000=> */
export enum ConfiguredCarFeatureState {
  Available = "available",
  Changeable = "changeable",
  InConflict = "inConflict",
  Selected = "selected",
  Standard = "standard",
}

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type ConfiguredCarFeatureText = CarFeatureText & {
  __typename?: "ConfiguredCarFeatureText";
  akHeadline?: Maybe<Scalars["String"]>;
  akRemarks?: Maybe<Scalars["String"]>;
  akText?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<Maybe<Scalars["String"]>>>;
  carpetText?: Maybe<Scalars["String"]>;
  carpetTitle?: Maybe<Scalars["String"]>;
  dashboardText?: Maybe<Scalars["String"]>;
  dashboardTitle?: Maybe<Scalars["String"]>;
  engineDescription?: Maybe<Scalars["String"]>;
  engineGear?: Maybe<Scalars["String"]>;
  engineName?: Maybe<Scalars["String"]>;
  engineNamesText?: Maybe<Scalars["String"]>;
  exteriorColorText?: Maybe<Scalars["String"]>;
  exteriorColorTitle?: Maybe<Scalars["String"]>;
  exteriorContrastColorText?: Maybe<Scalars["String"]>;
  exteriorContrastColorTitle?: Maybe<Scalars["String"]>;
  headliningText?: Maybe<Scalars["String"]>;
  headliningTitle?: Maybe<Scalars["String"]>;
  intro?: Maybe<Scalars["String"]>;
  seatsText?: Maybe<Scalars["String"]>;
  seatsTitle?: Maybe<Scalars["String"]>;
  teaserText?: Maybe<Scalars["String"]>;
};

/** Links to some additional information data for tyre features */
export type ConfiguredCarFeatureTyreLabel = {
  __typename?: "ConfiguredCarFeatureTyreLabel";
  /** Link to a product information sheet pdf */
  productSheetUrl?: Maybe<Scalars["String"]>;
  /** Link to an image with environmental informations for the tyre */
  tyreLabelUrl?: Maybe<Scalars["String"]>;
};

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type ConfiguredCarFeatureVideo = CarFeatureVideo & {
  __typename?: "ConfiguredCarFeatureVideo";
  /** Size - width: 888, height: 500 */
  bigVideoMp4?: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4?: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb?: Maybe<VideoSource>;
};

export type ConfiguredCarFeatures = {
  __typename?: "ConfiguredCarFeatures";
  data: Array<ConfiguredCarFeature>;
};

export type ConfiguredCarFeaturesDataArgs = {
  featuresFilterInput?: InputMaybe<FeaturesFilterInput>;
  financingInput?: InputMaybe<FinancingInput>;
};

/** Identifier to query a configured car */
export type ConfiguredCarIdentifier = {
  __typename?: "ConfiguredCarIdentifier";
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /** 3-digit pr numbers */
  equipmentOptions?: Maybe<Array<Scalars["String"]>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars["String"]>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars["String"]>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
  model: ConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars["String"];
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type ConfiguredCarInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions?: InputMaybe<Array<Scalars["String"]>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: InputMaybe<Scalars["String"]>;
  /** 2-digit pr number of the interior color */
  interiorColor?: InputMaybe<Scalars["String"]>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
  /** the model input separte in an own input */
  model: ConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros.
   */
  salesGroup?: InputMaybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfiguredCarMedia =
  | ConfiguredCarFeatureImage
  | ConfiguredCarFeatureText
  | ConfiguredCarFeatureVideo;

/** All available media resources for a configured car */
export type ConfiguredCarMediaResources = {
  __typename?: "ConfiguredCarMediaResources";
  ave?: Maybe<ConfiguredCarAve>;
  /** Contains media urls related to EEC labels. */
  eec?: Maybe<ConfiguredCarEecLabelImageUrls>;
  renderImages?: Maybe<ConfiguredCarRenderImages>;
};

export type ConfiguredCarModelBase = CarModelBase & {
  __typename?: "ConfiguredCarModelBase";
  id?: Maybe<ConfiguredCarModelIdentifier>;
  name?: Maybe<Scalars["String"]>;
};

/** Model identifier */
export type ConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: "ConfiguredCarModelIdentifier";
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars["String"];
  /** 7-digit pr numbers */
  extensions?: Maybe<Array<Scalars["String"]>>;
  /** 1-digit model version */
  version: Scalars["Int"];
  /** The year of the catalogue release */
  year: Scalars["Int"];
};

export type ConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars["String"];
  /** 7-digit pr numbers */
  extensions?: InputMaybe<Array<Scalars["String"]>>;
  /** 1-digit model version */
  version: Scalars["Int"];
  /** The year of the catalogue release/models release */
  year: Scalars["Int"];
};

export type ConfiguredCarPackage = ConfiguredCarFeatureInterface & {
  __typename?: "ConfiguredCarPackage";
  /** Family of the package */
  family: CarFeatureFamily;
  /** Feature group mapping */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  /** Features contained in the package */
  features: ConfiguredCarFeatures;
  financingParameters?: Maybe<FinancingParameters>;
  /** The feature group of the package */
  group: CarFeatureGroup;
  images?: Maybe<ConfiguredCarFeatureImage>;
  /** e.g. 'Comfort & Sound pack' */
  name: Scalars["String"];
  /** e.g. 'PG2' */
  pr3: Scalars["ID"];
  /** e.g. 'GPG2PG2' */
  pr7: Scalars["ID"];
  /** Price for this feature */
  price?: Maybe<Price>;
  rate?: Maybe<PartRateResult>;
  /** Status flags of the package */
  status: Status;
  /** Marketing text blocks */
  texts?: Maybe<ConfiguredCarFeatureText>;
  /** Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'. */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

export type ConfiguredCarPackages = {
  __typename?: "ConfiguredCarPackages";
  data?: Maybe<Array<ConfiguredCarPackage>>;
};

export type ConfiguredCarPackagesDataArgs = {
  featuresFilterInput?: InputMaybe<FeaturesFilterInput>;
  financingInput?: InputMaybe<FinancingInput>;
};

export type ConfiguredCarPrices = CarPrices & {
  __typename?: "ConfiguredCarPrices";
  /** bpm (first-time-registration) tax (Belastingdienst - NL only) */
  bpm?: Maybe<CarPrice>;
  /** lowest price of the configured car´s carline */
  carlineBase?: Maybe<CarPrice>;
  /** delivery cost excluded from total */
  charges?: Maybe<CarPrice>;
  /** total price excluding options which are not financeable */
  financeableTotal?: Maybe<CarPrice>;
  financingParameters?: Maybe<FinancingParameters>;
  /** base price of model without extra options */
  model?: Maybe<CarPrice>;
  monthlyRate?: Maybe<MonthlyRateResult>;
  /** netto price of the model (FI only) */
  nettoBase?: Maybe<CarPrice>;
  /** netto price of the model´s options (FI only) */
  nettoOptions?: Maybe<CarPrice>;
  /** total price excluding financeable total */
  nonFinanceableTotal?: Maybe<CarPrice>;
  /** prices of all options contained in the configured car */
  options?: Maybe<CarPrice>;
  /** creation date for the delivered prices */
  priceDate?: Maybe<PriceDate>;
  /** On The Road Price (UK market), without additional Servicepackgages (= total + otrOffset) */
  rotr?: Maybe<CarPrice>;
  /** On The Road Price offset (UK market) */
  rotrRate?: Maybe<CarPrice>;
  /** price for selected service packages */
  servicePackage?: Maybe<CarPrice>;
  /** total tax (FI and NO markets) */
  taxTotal?: Maybe<CarPrice>;
  /** total tax depending on CO2 consumptions (NO market only) */
  taxTotalCO2?: Maybe<CarPrice>;
  /** total tax price excluding  scrap fee (NO market only) */
  taxTotalExclScrapFree?: Maybe<CarPrice>;
  /** total tax depending on NO2 consumptions (NO market only) */
  taxTotalNOx?: Maybe<CarPrice>;
  /** total tax depending on weight (NO market only) */
  taxTotalWeight?: Maybe<CarPrice>;
  /** taxable benefits (FI only) */
  taxableBenefit1?: Maybe<CarPrice>;
  /** taxable benefits (FI only) */
  taxableBenefit2?: Maybe<CarPrice>;
  /** total price of the configured car */
  total?: Maybe<CarPrice>;
  /** total price with additional charges */
  totalWithCharges?: Maybe<CarPrice>;
  /** value added taxes (vat) for this model */
  vat?: Maybe<CarPrice>;
};

/**
 * Render images for a configured car
 *
 * mapping for dpu based render images
 * n2c01: ["exterior_front", "layerstage.new"],
 * n2c02: ["closeup_lights", "layerstage.new"],
 * n2c12: ["exterior_back", "layerstage.new"],
 * n2c14: ["exterior", "layerstage.new"],
 * n2c15: ["closeup_wheels", "layerstage.new"],
 * n2n05: ["interior", "layerstage.new"],
 * n2n06: ["dashboard", "layerstage.new"],
 * n2n10: ["rearseat", "layerstage.new"],
 * n3c01: ["exterior_front", "stage.new"],
 * n3c02: ["closeup_lights", "stage.new"],
 * n3c12: ["exterior_back", "stage.new"],
 * n3c14: ["exterior", "stage.new"],
 * n3c15: ["closeup_wheels", "stage.new"],
 * n3n05: ["interior", "stage.new"],
 * n3n06: ["dashboard", "stage.new"],
 * n3n10: ["rearseat", "stage.new"],
 * n4c01: ["exterior_front", "fullscreen.new"],
 * n4c02: ["closeup_lights", "fullscreen.new"],
 * n4c12: ["exterior_back", "fullscreen.new"],
 * n4c14: ["exterior", "fullscreen.new"],
 * n4c15: ["closeup_wheels", "fullscreen.new"],
 * n4n05: ["interior", "fullscreen.new"],
 * n4n06: ["parent.dashboard", "fullscreen.new"],
 * n4n10: ["rearseat", "fullscreen.new"],
 * n5c01: ["exterior_front", "carstore.new"],
 * n8c01: ["exterior_front", "compare.new"],
 * n9c14: ["exterior", "modelfinder.new"],
 * nmfc: ["exterior", "flyout"],
 */
export type ConfiguredCarRenderImages = {
  __typename?: "ConfiguredCarRenderImages";
  n2c01?: Maybe<Scalars["String"]>;
  n2c02?: Maybe<Scalars["String"]>;
  n2c12?: Maybe<Scalars["String"]>;
  n2c14?: Maybe<Scalars["String"]>;
  n2c15?: Maybe<Scalars["String"]>;
  n2n05?: Maybe<Scalars["String"]>;
  n2n06?: Maybe<Scalars["String"]>;
  n2n10?: Maybe<Scalars["String"]>;
  n3c01?: Maybe<Scalars["String"]>;
  n3c02?: Maybe<Scalars["String"]>;
  n3c12?: Maybe<Scalars["String"]>;
  n3c14?: Maybe<Scalars["String"]>;
  n3c15?: Maybe<Scalars["String"]>;
  n3n05?: Maybe<Scalars["String"]>;
  n3n06?: Maybe<Scalars["String"]>;
  n3n10?: Maybe<Scalars["String"]>;
  n4c01?: Maybe<Scalars["String"]>;
  n4c02?: Maybe<Scalars["String"]>;
  n4c12?: Maybe<Scalars["String"]>;
  n4c14?: Maybe<Scalars["String"]>;
  n4c15?: Maybe<Scalars["String"]>;
  n4n05?: Maybe<Scalars["String"]>;
  n4n06?: Maybe<Scalars["String"]>;
  n4n10?: Maybe<Scalars["String"]>;
  n5c01?: Maybe<Scalars["String"]>;
  n8c01?: Maybe<Scalars["String"]>;
  n9c14?: Maybe<Scalars["String"]>;
  nmfc?: Maybe<Scalars["String"]>;
};

/** Implementation of the TechnicalData interface for configurations. */
export type ConfiguredCarTechnicalData = TechnicalData & {
  __typename?: "ConfiguredCarTechnicalData";
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: 9,5 s
   * TODO: question: How to handle the name: Beschleunigung 0-100 km/
   */
  accelerationTime?: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType?: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes?: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume?: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch?: Maybe<TechnicalDataTextItem>;
  /** Consumptions and emissions. */
  consumptionAndEmission?: Maybe<TechnicalDataConsumptionAndEmission>;
  /** Energy Efficiency Certificate. */
  eec?: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   * @deprecated No longer supported
   */
  electricRangeCombined?: Maybe<TechnicalDataRangeItem>;
  /**
   * Array of the engines for this vehicle. Minimal one entry
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: TechnicalDataEngineBase;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: 7-Gang S tronic
   */
  gearbox?: Maybe<TechnicalDataTextItem>;
  /** Class of several insurance related attributes @cacheControl(maxAge: 300) */
  insuranceClass?: Maybe<TechnicalDataInsuranceClass>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard?: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Rims of the vehicle
   * f.ex. "Räder, 5-Arm-Design, 6 J x 15, Reifen 185/65 R 15"
   */
  rims?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad?: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * (https://auto.schema.org/speed)
   * f.ex. 250 km/h
   */
  speed?: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem?: Maybe<TechnicalDataTextItem>;
  /** Debug Value */
  tenant?: Maybe<Scalars["String"]>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked?: Maybe<TechnicalDataFloatItem>;
  /**
   * Tyres of the vehicle
   * f.ex. "Reifen 185/65 R15 92 V xl"
   */
  tyres?: Maybe<TechnicalDataTextItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal?: Maybe<TechnicalDataFloatItem>;
};

/** An indication about if a color should be chosen from a brighter or darker color scheme */
export enum ContrastThemeHint {
  Dark = "DARK",
  Light = "LIGHT",
}

/** Currency information */
export type Currency = {
  __typename?: "Currency";
  /** ISO 4217 currency code. For example, "USD" */
  code: Scalars["ID"];
  /** Symbol of the currency. For example, "$" */
  symbol?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type DataEntry = {
  __typename?: "DataEntry";
  disclaimer?: Maybe<Scalars["String"]>;
  formattedValue: Scalars["String"];
  id: Scalars["String"];
  rawValue?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsolidated = {
  __typename?: "EACConsolidated";
  unit?: Maybe<Scalars["String"]>;
  value?: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsumption = {
  __typename?: "EACConsumption";
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSet = {
  __typename?: "EACDataSet";
  em?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  values?: Maybe<EacDataSetValues>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSetValues = {
  __typename?: "EACDataSetValues";
  combined?: Maybe<EacValue>;
  extraHigh?: Maybe<EacValue>;
  high?: Maybe<EacValue>;
  low?: Maybe<EacValue>;
  medium?: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmission = {
  __typename?: "EACEmission";
  co2?: Maybe<EacEmissionType>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmissionType = {
  __typename?: "EACEmissionType";
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEntry = {
  __typename?: "EACEntry";
  consolidated?: Maybe<EacConsolidated>;
  dataSets?: Maybe<Array<Maybe<EacDataSet>>>;
  /** "datasets" is deprecated. Use "dataSets" (mixedCase) instead. */
  datasets?: Maybe<Array<Maybe<EacDataSet>>>;
  unit?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFootnote = {
  __typename?: "EACFootnote";
  reference?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFuel = {
  __typename?: "EACFuel";
  consumption?: Maybe<EacConsumption>;
  emission?: Maybe<EacEmission>;
  fuel?: Maybe<Scalars["String"]>;
  range?: Maybe<EacRange>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacRange = {
  __typename?: "EACRange";
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacValue = {
  __typename?: "EACValue";
  formatted?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Float"]>;
  text?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EmissionAndConsumption = {
  __typename?: "EmissionAndConsumption";
  eec?: Maybe<Scalars["String"]>;
  emissionClass?: Maybe<Scalars["String"]>;
  footnote?: Maybe<EacFootnote>;
  formattedConsumption?: Maybe<Scalars["String"]>;
  formattedEmission?: Maybe<Scalars["String"]>;
  formattedFuels?: Maybe<Array<Maybe<Scalars["String"]>>>;
  fuels?: Maybe<Array<Maybe<EacFuel>>>;
  hasNedc?: Maybe<Scalars["Boolean"]>;
  hasWltp?: Maybe<Scalars["Boolean"]>;
  vehicleType?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Engine = {
  __typename?: "Engine";
  code: Scalars["String"];
  name: Scalars["String"];
};

/** Collection of named exterior views. Each key maps to a render image URL. */
export type ExteriorViews = {
  __typename?: "ExteriorViews";
  /** The front view, slightly angled. */
  beautyFront: Scalars["String"];
  /** The rear view, slightly angled. */
  beautyRear: Scalars["String"];
  /** The front view. */
  front: Scalars["String"];
  /** The side view. */
  side: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Family = {
  __typename?: "Family";
  id: Scalars["String"];
  name: Scalars["String"];
};

/**
 * Feature change request input required to modify a given ConfiguredCar. If autoResolveConflicts is enabled
 * eventual conflicting results will be solved automatically with the best effort approach.
 */
export type FeatureChange = {
  /** action to be performed with this feature on the ConfiguredCar: "ADD", "REMOVE", etc. */
  action: FeatureChangeAction;
  /** indicates if an eventual conflicting configuration result after adding/removing this feature should be automatically resolved */
  autoResolveConflicts: Scalars["Boolean"];
  /** ConfiguredCarFeature's pr3 or pr7 value */
  id: Scalars["ID"];
};

/** Possible actions that can be taken with a feature in relation to a ConfiguredCar */
export enum FeatureChangeAction {
  Add = "ADD",
  Remove = "REMOVE",
}

/** features can be filtered by their attributes (e.g. configuration state) */
export type FeaturesFilterInput = {
  /** filter features by family ID (family IDs are market specific and can differ for each market!) */
  filterByFamilyId?: InputMaybe<Scalars["String"]>;
  /** filter features by one or more feature groups (feature groups in the array behave like a logic OR) */
  filterByFeatureGroup?: InputMaybe<Array<ConfiguredCarFeatureGroup>>;
  /**
   * filter features by one ore more conditions (array) based on their configuration state (available, changeable,
   * inConflict, selected,  standard)
   */
  filterByFeatureState?: InputMaybe<Array<ConfiguredCarFeatureState>>;
  /** filter features by group ID (groupIDs are market specific and can differ for each market!) */
  filterByGroupId?: InputMaybe<Scalars["String"]>;
};

/** An configuration option for which a rate should be calculated by Financing-GraphQl. */
export type FinanceablePart = {
  __typename?: "FinanceablePart";
  pr: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type FinanceablePartRate = {
  __typename?: "FinanceablePartRate";
  currency?: Maybe<FinancingCurrency>;
  formattedValue?: Maybe<Scalars["String"]>;
  rawValue?: Maybe<Scalars["Float"]>;
  valueAsText?: Maybe<Scalars["String"]>;
};

/** @deprecated calculation moved into financing-graphql subgraph */
export type Financing = {
  __typename?: "Financing";
  globalDisclaimer?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
  productDisclaimer?: Maybe<Scalars["String"]>;
  rate?: Maybe<Price>;
  summaryDisclaimer?: Maybe<Scalars["String"]>;
};

export type FinancingCurrency = {
  __typename?: "FinancingCurrency";
  code?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
};

export type FinancingDisclaimers = {
  __typename?: "FinancingDisclaimers";
  calculationDisclaimer?: Maybe<Scalars["String"]>;
  globalDisclaimer?: Maybe<Scalars["String"]>;
  productDisclaimer?: Maybe<Scalars["String"]>;
  rateDetails?: Maybe<Scalars["String"]>;
};

export type FinancingError = {
  __typename?: "FinancingError";
  code: Scalars["String"];
  message: Scalars["String"];
  type: Scalars["String"];
};

export type FinancingGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: "FinancingGraphqlInfo";
  endpoint: Scalars["String"];
  health: OneGraphServiceHealth;
  id: Scalars["ID"];
  meta: OneGraphServiceMetaInfo;
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars["String"];
};

/** Financing Product information */
export type FinancingInput = {
  /** e.g. VC for VarioCredit */
  product: Scalars["String"];
  productParameters: Array<InputMaybe<FinancingProductParameterInput>>;
};

/** Model information used for financing calculations */
export type FinancingModelParameters = {
  __typename?: "FinancingModelParameters";
  code: Scalars["String"];
  version: Scalars["Int"];
  year: Scalars["Int"];
};

/** All required data for financing calculation. Additional data is provided by Financing-GraphQl through federation. */
export type FinancingParameters = {
  __typename?: "FinancingParameters";
  calculationParameters?: Maybe<Array<Maybe<FinancingProductParameter>>>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  disclaimers?: Maybe<FinancingDisclaimers>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
  model: FinancingModelParameters;
  modelPrice: Scalars["Float"];
  part?: Maybe<FinanceablePart>;
  product?: Maybe<FinancingProduct>;
  productName?: Maybe<Scalars["String"]>;
  /** 5-digit sales group */
  salesGroup: Scalars["String"];
  totalPrice: Scalars["Float"];
};

/** Financing Product information */
export type FinancingProduct = {
  __typename?: "FinancingProduct";
  id: Scalars["String"];
  productParameters: Array<Maybe<FinancingProductParameter>>;
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameter = {
  __typename?: "FinancingProductParameter";
  key: Scalars["String"];
  value: Scalars["String"];
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameterInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Group = {
  __typename?: "Group";
  id: Scalars["String"];
  name: Scalars["String"];
};

/** @deprecated queries deprecated */
export enum IdentifierType {
  Audicode = "AUDICODE",
  Carline = "CARLINE",
  Prstring = "PRSTRING",
}

/** @deprecated data moved into `ConfiguredCar` */
export type Image = {
  __typename?: "Image";
  url?: Maybe<Scalars["String"]>;
};

/** Defines an image by size, MIME type and source URL. */
export type ImageSource = {
  __typename?: "ImageSource";
  /** The height of the image in pixels, e.g. 600. */
  height: Scalars["Int"];
  /** The MIME type of the image, e.g. "image/png". */
  mimeType: Scalars["String"];
  /** The source of the image, e.g. "https://www.mydomain.com/myimage.png" */
  url: Scalars["String"];
  /** The width of the image in pixels, e.g. 800. */
  width: Scalars["Int"];
};

/** @deprecated renamed to `ConfiguredCarFeatureGroup` */
export enum ItemGroup {
  Accessories = "ACCESSORIES",
  ExteriorColors = "EXTERIOR_COLORS",
  InteriorTiles = "INTERIOR_TILES",
  Lights = "LIGHTS",
  Rims = "RIMS",
  Seats = "SEATS",
  SteeringWheels = "STEERING_WHEELS",
}

/** @deprecated queries deprecated */
export type MarketContext = {
  country: Scalars["String"];
  language: Scalars["String"];
  previewContext?: InputMaybe<Scalars["String"]>;
};

/** information to specify a market */
export type MarketIdentifierInput = {
  /** KVPS brand id. For example `A` for Audi. */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
};

export type MonthlyRate = {
  __typename?: "MonthlyRate";
  currency?: Maybe<FinancingCurrency>;
  formattedValue?: Maybe<Scalars["String"]>;
  rawValue?: Maybe<Scalars["Float"]>;
  valueAsText?: Maybe<Scalars["String"]>;
};

export type MonthlyRateResult = FinancingError | MonthlyRate;

export type Mutation = {
  __typename?: "Mutation";
  /** @deprecated not implemented */
  configurationChange: Configuration;
};

export type MutationConfigurationChangeArgs = {
  change: ChangeAction;
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
};

/**
 * %DELETE TYPE%
 * Informations about the OneGraph, the OneGraphSubServices and additional DownStreamServices
 * The services can/should deliver informations about
 *  - health
 *  - endpoint
 *  - meta (such as contact, issueTracker, Channel,...)
 *  - down stream services of the sub services (important for checking, visualising the state of OneGraph)
 *
 * Infos about SubServices can be federated in this type
 * f.ex
 *
 * extend type OneGraphInfo @key(fields: id) @shareable {
 *     id: ID! @external
 *     mySubService: MySubServiceInfo!
 * }
 *
 * type MySubServiceInfo implements OneGraphSubServiceInfo @shareable {
 * ...
 * }
 */
export type OneGraphInfo = {
  __typename?: "OneGraphInfo";
  audicodeGraphqlInfo: AudicodeGraphqlInfo;
  carlineInfoServiceInfo: CarlineInfoServiceInfo;
  colaServiceInfo: ColaServiceInfo;
  /** GraphQL endpoint of the OneGraph */
  endpoint: Scalars["String"];
  financingGraphqlInfo: FinancingGraphqlInfo;
  /**
   * ID of the OneGraph (derived from the version)
   * @deprecated Use `version`.
   */
  id: Scalars["ID"];
  /** GraphQL endpoint of the OneGraph */
  meta: OneGraphServiceMetaInfo;
  /** Name of the OneGraph: 'OneGraph' */
  name: Scalars["String"];
  /** information and health status about the Stock-Car subgraph */
  stockcarGraphqlInfo: StockcarGraphqlInfo;
  tedasServiceInfo: TedasServiceInfo;
  /** Software Version of the OneGraph-Gateway */
  version: Scalars["String"];
};

/**
 * MetaInfo of the service
 * The service might be the OneGraph itself, the sub services or the downstream service
 */
export type OneGraphServiceContactInfo = {
  __typename?: "OneGraphServiceContactInfo";
  /** Email of the contact */
  email?: Maybe<Scalars["String"]>;
  /**
   * Name of the contact.
   * Provide title, fistname and lastname in one field
   */
  name: Scalars["String"];
  /** Role of the contact */
  role: OneGraphServiceContactRoleEnum;
};

/**
 * TODO; Verify the roles (Complete?)
 * The role of a contact
 */
export enum OneGraphServiceContactRoleEnum {
  /** Role Developer */
  Developer = "DEVELOPER",
  /**
   * Role Product Owner
   * In an Audi Context an Audi reponsibility
   */
  ProductOwner = "PRODUCT_OWNER",
  /** Role Proxy Product Owner */
  ProxyProductOwner = "PROXY_PRODUCT_OWNER",
  /**
   * Support
   * Might be a support contact whithin the service or an exteranl support supplier
   */
  Support = "SUPPORT",
}

/**
 * There are currently two locations in the info graph where service health might be indicated
 * - OneGraphSubService: Should indicate health of one (or more) downstreamservice
 * - OneGraphDownStreamService: Should health of downstream service. This should be requested by the SubService
 */
export type OneGraphServiceHealth = {
  __typename?: "OneGraphServiceHealth";
  /** URL of the check endpoint (might be important for troubleshooting and support) */
  checkEndpoint?: Maybe<Scalars["String"]>;
  /** Code (might be an error code of the Sub/DownStream-Service or http error code) */
  code?: Maybe<Scalars["String"]>;
  /** Message related to the code */
  message?: Maybe<Scalars["String"]>;
  /** Status of the Service */
  status: OneGraphServiceStatusEnum;
};

/**
 * Meta information about the Service
 * (Might be the OneGraph, the Subservices or the DownstreamServices)
 */
export type OneGraphServiceMetaInfo = {
  __typename?: "OneGraphServiceMetaInfo";
  /**
   * URL of an direct communication channel.
   * In an Audi context a link to a rocket chat channel
   */
  channel?: Maybe<Scalars["String"]>;
  /** Contact info for the service. */
  contact?: Maybe<Array<OneGraphServiceContactInfo>>;
  /**
   * Homepage of the service to get more detailled information.
   * In an Audi context a link to a confluence page.
   * Might be also an link to the specification/support of this service
   */
  homepage: Scalars["String"];
  /**
   * URL to the issue tracker.
   * In an Audi context a link to a jira project
   */
  issueTracker: Scalars["String"];
  /** Repository for the source code of the service */
  repo?: Maybe<Scalars["String"]>;
  /** Name of the team which is responsible for the service */
  team: Scalars["String"];
};

/**
 * Indicates the service status
 * Derived from https://datatracker.ietf.org/doc/html/draft-inadarei-api-health-check-05#section-3.1
 */
export enum OneGraphServiceStatusEnum {
  /** Failure in Service */
  Fail = "FAIL",
  /** Service is ok */
  Pass = "PASS",
  /** Warning in Service */
  Warn = "WARN",
}

/**
 * TODO: Separate types for different kinds of downstream services?
 * Info about a downstream service.
 * A donwstream service is an external ressource which will be used by a aub service.
 * This can be a Rest/GraphQL/SOAP-Endppoint or a database or something else
 */
export type OneGraphSubServiceDownStreamInfo = {
  __typename?: "OneGraphSubServiceDownStreamInfo";
  /** Endpoint of the downstream service */
  endpoint?: Maybe<Scalars["String"]>;
  /** Healthinfo about the downstream service */
  health?: Maybe<OneGraphServiceHealth>;
  /**
   * ID of the downstream service.
   * Fill something that makews sense in the context of the downstream service
   * f.ex. a REST-ressource might deliver the version of the API
   */
  id: Scalars["ID"];
  /** MetaInfo about the downstream service */
  meta?: Maybe<OneGraphServiceMetaInfo>;
  /** Name of the downstream service */
  name: Scalars["String"];
};

/**
 * Interface thas has to implemented by each subservice opf the OneGraph
 * TODO: Information about the running plattform (f.ex AWS, SPR, WebCentzer, Cocos,...)?
 * TODO: Programming language and frameworks?
 */
export type OneGraphSubServiceInfo = {
  /** Endpoint of the sub service */
  endpoint: Scalars["String"];
  /** Health of the sub service */
  health: OneGraphServiceHealth;
  /**
   * Identifier of the sub service
   * Might be the version, git commit hash, ....
   * Should be human readable
   */
  id: Scalars["ID"];
  /** MetaInfo of the sub service */
  meta: OneGraphServiceMetaInfo;
  /** Name of the sub service */
  name: Scalars["String"];
  /** External downstream services of the sub service */
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
};

/** @deprecated data moved into `ConfiguredCar.catalog` */
export type Option = {
  __typename?: "Option";
  containsOptions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  family: Family;
  financingParameters?: Maybe<FinancingParameters>;
  group: Group;
  hasDetails?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  images?: Maybe<Array<Image>>;
  isContainedInOptions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  itemGroup?: Maybe<ItemGroup>;
  itemType: Scalars["String"];
  mbvId: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<OptionPrice>;
  rate?: Maybe<PartRateResult>;
  status: Status;
  tyreLabels?: Maybe<Array<Maybe<TyreLabel>>>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type OptionPrice = {
  __typename?: "OptionPrice";
  currency?: Maybe<Scalars["String"]>;
  formattedValue?: Maybe<Scalars["String"]>;
  priceMode?: Maybe<PriceMode>;
  rawValue?: Maybe<Scalars["Float"]>;
};

/**
 * Pagination - we're 'blocking' this type to make sure that it isn't be used outside core definition.
 * TODO: check original type
 */
export type PageInfo = {
  __typename?: "PageInfo";
  /** Indicates whether there's a next page */
  hasNextPage: Scalars["Boolean"];
  /** Indicates whether there's a previous page */
  hasPreviousPage: Scalars["Boolean"];
};

export type PartRateResult = FinanceablePartRate | FinancingError;

/** information to specify a dealer */
export type PartnerInfo = {
  /** use data of the dealer group instead of a single dealer */
  includeGroupStock?: InputMaybe<Scalars["Boolean"]>;
  /** KVPS id of the dealer */
  partnerId: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Price = CarPrice & {
  __typename?: "Price";
  /** @deprecated No longer supported */
  currency?: Maybe<Scalars["String"]>;
  currencyDetails?: Maybe<Currency>;
  formattedValue?: Maybe<Scalars["String"]>;
  /** @deprecated No longer supported */
  rawValue?: Maybe<Scalars["String"]>;
  value: Scalars["Float"];
  valueAsText?: Maybe<Scalars["String"]>;
};

export type PriceDate = {
  __typename?: "PriceDate";
  date?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

/** @deprecated queries deprecated */
export enum PriceMode {
  Price = "PRICE",
  Rate = "RATE",
}

/** @deprecated data moved into `ConfiguredCar` */
export type Prices = {
  __typename?: "Prices";
  base?: Maybe<Price>;
  bpm?: Maybe<Price>;
  carlineBase?: Maybe<Price>;
  charges?: Maybe<Price>;
  financeableTotal?: Maybe<Price>;
  /** @deprecated financing information moved into financingParameters */
  financing?: Maybe<Financing>;
  financingParameters?: Maybe<FinancingParameters>;
  monthlyRate?: Maybe<MonthlyRateResult>;
  nettoBase?: Maybe<Price>;
  nettoOptions?: Maybe<Price>;
  nonFinanceableTotal?: Maybe<Price>;
  options?: Maybe<Price>;
  rotr?: Maybe<Price>;
  rotrRate?: Maybe<Price>;
  servicePackage?: Maybe<Price>;
  taxTotal?: Maybe<Price>;
  taxTotalCO2?: Maybe<Price>;
  taxTotalExclScrapFree?: Maybe<Price>;
  taxTotalNOx?: Maybe<Price>;
  taxTotalWeight?: Maybe<Price>;
  taxableBenefit1?: Maybe<Price>;
  taxableBenefit2?: Maybe<Price>;
  total?: Maybe<Price>;
  /** @deprecated No longer supported */
  totalRate?: Maybe<Price>;
  totalWithCharges?: Maybe<Price>;
  vat?: Maybe<Price>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type Query = {
  __typename?: "Query";
  attributes?: Maybe<Array<Maybe<CarlineAttribute>>>;
  /** information about the stocks of a market */
  availableStock: AvailableStock;
  carByCode?: Maybe<AudiCodeCar>;
  carCodeTechnicalData: ConfiguredCarTechnicalData;
  carline: CarlineStructureCarline;
  carlineById: CarlineStructureCarline;
  carlineGroup: CarlineGroup;
  carlineInfoServiceStatus: CarlineStatus;
  carlineStructure: CarlineStructure;
  /** A query to change a configured car by adding or removing a feature. */
  changeConfiguredCarFeature: ChangeConfiguredCarPayload;
  /** A query to change a configured car's model. */
  changeConfiguredCarModel: ChangeConfiguredCarPayload;
  /** @deprecated Use query configuredCar. */
  configuration: Configuration;
  /** @deprecated Use query configuredCarByCarlineWithFeatures. */
  configurationInitWithItems: Configuration;
  /** Returns the configured car defined by the input object */
  configuredCar: ConfiguredCar;
  /** Resolve the default configuration for the given carline. */
  configuredCarByCarline: ConfiguredCar;
  /** Resolve the lowest priced ConfiguredCar for the given carline including the required features. */
  configuredCarByCarlineWithFeatures: ConfiguredCar;
  /**
   * Changes the given configuration by adding or removing a new feature. It additionally can auto-resolve buildability
   * conflicts and return status information about it.
   * @deprecated Please use the new query chnageConfigurationFeature
   */
  configuredCarChange: ChangedConfiguredCar;
  configuredCarPackages?: Maybe<Array<ConfigurationPackage>>;
  /** Query for technical data for configured car */
  configuredCarTechnicalData: ConfiguredCarTechnicalData;
  /** @deprecated Use configuredCar instead */
  getConfiguredCar: ConfiguredCar;
  /** @deprecated Use configuredCarPackages instead */
  getConfiguredCarPackages?: Maybe<Array<ConfigurationPackage>>;
  /**
   * Query for technical data for configured car
   * @deprecated use `configuredCarTechnicalData` instead
   */
  getTechnicalDataForConfiguredCar: ConfiguredCarTechnicalData;
  /** @deprecated Use query configuredCar and corresponding catalog fields with optional filters. */
  itemsByGroup: Array<Option>;
  markets: CarlineMarkets;
  /** Get information about the OneGraph itself */
  oneGraphInfo?: Maybe<OneGraphInfo>;
  unfilteredCarlineStructure: CarlineStructure;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryAttributesArgs = {
  identifier?: InputMaybe<CarlineIdentifierInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryAvailableStockArgs = {
  marketIdentifier: MarketIdentifierInput;
  partnerInfo?: InputMaybe<PartnerInfo>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarByCodeArgs = {
  code: Scalars["String"];
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarCodeTechnicalDataArgs = {
  code: TedasCarCode;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineArgs = {
  identifier?: InputMaybe<CarlineIdentifierInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineByIdArgs = {
  identifier?: InputMaybe<CarlineIdentifierInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineGroupArgs = {
  identifier?: InputMaybe<CarlineGroupIdentifierInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineStructureArgs = {
  identifier?: InputMaybe<CarlineStructureIdentifierInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConfiguredCarFeatureArgs = {
  input: ChangeConfiguredCarFeatureInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConfiguredCarModelArgs = {
  input: ChangeConfiguredCarModelInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfigurationArgs = {
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfigurationInitWithItemsArgs = {
  carline: Scalars["String"];
  items: Array<Scalars["String"]>;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarByCarlineArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarByCarlineWithFeaturesArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
  features: Array<Scalars["String"]>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarChangeArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  featureChange: FeatureChange;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarTechnicalDataArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetTechnicalDataForConfiguredCarArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryItemsByGroupArgs = {
  configIdentifier: ConfigurationIdentifier;
  itemGroup: ItemGroup;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryUnfilteredCarlineStructureArgs = {
  identifier?: InputMaybe<CarlineStructureIdentifierInput>;
};

export type Remark = {
  __typename?: "Remark";
  /** Unique ID of the remark */
  id: Scalars["String"];
  /** Text of the remark */
  value?: Maybe<Scalars["String"]>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type RenderImages = {
  __typename?: "RenderImages";
  closeupLightsFullscreen?: Maybe<Scalars["String"]>;
  closeupLightsLayerstage?: Maybe<Scalars["String"]>;
  closeupLightsStage?: Maybe<Scalars["String"]>;
  closeupWheelsFullscreen?: Maybe<Scalars["String"]>;
  closeupWheelsLayerstage?: Maybe<Scalars["String"]>;
  closeupWheelsStage?: Maybe<Scalars["String"]>;
  dashboardFullscreen?: Maybe<Scalars["String"]>;
  dashboardLayerstage?: Maybe<Scalars["String"]>;
  dashboardStage?: Maybe<Scalars["String"]>;
  exteriorBackFullscreen?: Maybe<Scalars["String"]>;
  exteriorBackLayerstage?: Maybe<Scalars["String"]>;
  exteriorBackStage?: Maybe<Scalars["String"]>;
  exteriorFrontCarstore?: Maybe<Scalars["String"]>;
  exteriorFrontCompare?: Maybe<Scalars["String"]>;
  exteriorFrontFullscreen?: Maybe<Scalars["String"]>;
  exteriorFrontLayerstage?: Maybe<Scalars["String"]>;
  exteriorFrontStage?: Maybe<Scalars["String"]>;
  exteriorFullscreen?: Maybe<Scalars["String"]>;
  exteriorLayerstage?: Maybe<Scalars["String"]>;
  exteriorModelfinder?: Maybe<Scalars["String"]>;
  exteriorStage?: Maybe<Scalars["String"]>;
  interiorFullscreen?: Maybe<Scalars["String"]>;
  interiorLayerstage?: Maybe<Scalars["String"]>;
  interiorStage?: Maybe<Scalars["String"]>;
  n2c01?: Maybe<Scalars["String"]>;
  n2c02?: Maybe<Scalars["String"]>;
  n2c12?: Maybe<Scalars["String"]>;
  n2c14?: Maybe<Scalars["String"]>;
  n2c15?: Maybe<Scalars["String"]>;
  n2n05?: Maybe<Scalars["String"]>;
  n2n06?: Maybe<Scalars["String"]>;
  n2n10?: Maybe<Scalars["String"]>;
  n3c01?: Maybe<Scalars["String"]>;
  n3c02?: Maybe<Scalars["String"]>;
  n3c12?: Maybe<Scalars["String"]>;
  n3c14?: Maybe<Scalars["String"]>;
  n3c15?: Maybe<Scalars["String"]>;
  n3n05?: Maybe<Scalars["String"]>;
  n3n06?: Maybe<Scalars["String"]>;
  n3n10?: Maybe<Scalars["String"]>;
  n4c01?: Maybe<Scalars["String"]>;
  n4c02?: Maybe<Scalars["String"]>;
  n4c12?: Maybe<Scalars["String"]>;
  n4c14?: Maybe<Scalars["String"]>;
  n4c15?: Maybe<Scalars["String"]>;
  n4n05?: Maybe<Scalars["String"]>;
  n4n06?: Maybe<Scalars["String"]>;
  n4n10?: Maybe<Scalars["String"]>;
  n5c01?: Maybe<Scalars["String"]>;
  n8c01?: Maybe<Scalars["String"]>;
  n9c14?: Maybe<Scalars["String"]>;
  rearseatFullscreen?: Maybe<Scalars["String"]>;
  rearseatLayerstage?: Maybe<Scalars["String"]>;
  rearseatStage?: Maybe<Scalars["String"]>;
};

/** A Solution represents a solution or alternative to solve a conflict within one SolutionGroup. */
export type Solution = {
  __typename?: "Solution";
  /** Defines whether the `feature` has to be added or removed to form a solution. */
  action: FeatureChangeAction;
  /** Defines which feature has to be added or removed to form a solution. */
  feature: ConfiguredCarFeature;
  /** the usage of the solution may come with additional side effects (e.g. would remove other features) */
  sideEffects: Array<SolutionSideEffect>;
};

/** A SolutionGroup represents a list of alternatives/solutions to solve a conflict. */
export type SolutionGroups = {
  __typename?: "SolutionGroups";
  solutions: Array<Solution>;
};

/**
 * A Solution side effect represents a solution that
 * 1. will be automatically applied if the containing solution will be selected
 * OR
 * 2. has already occured while changing a configuration.
 */
export type SolutionSideEffect = {
  __typename?: "SolutionSideEffect";
  /** Defines if the side effect is an addition or removal. */
  action: FeatureChangeAction;
  /** Defines the feature of the side effect. */
  feature: ConfiguredCarFeature;
};

/** The status of a configured model, package or feature */
export type Status = {
  __typename?: "Status";
  available: Scalars["Boolean"];
  changeable: Scalars["Boolean"];
  inConflict: Scalars["Boolean"];
  selected: Scalars["Boolean"];
  standard: Scalars["Boolean"];
};

/** information about one stock */
export type Stock = {
  __typename?: "Stock";
  /** number of cars */
  amount: Scalars["Int"];
  /** url for stock frontend */
  url: Scalars["String"];
};

/** information about a Stock-Car */
export type StockCar = Car & {
  __typename?: "StockCar";
  /** Carline information of a car. */
  carline?: Maybe<StockCarCarline>;
  /** information about the AudiCode of a car */
  code: StockCarCarCode;
  /** Model information of a car. */
  model?: Maybe<StockCarModel>;
  /** Price information of a car. */
  prices?: Maybe<StockCarCarPrices>;
};

/** information about the AudiCode of a car */
export type StockCarCarCode = CarCode & {
  __typename?: "StockCarCarCode";
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars["String"]>;
  /**
   * Unique identifier for the car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars["String"];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars["String"]>;
  /** URL behind the Code (AudiCode). Can be used to open the car in a browser. */
  url: Scalars["String"];
};

/** Identifier for a model of a car. Contains necessary Information about the car model */
export type StockCarCarModelIdentifier = CarModelIdentifier & {
  __typename?: "StockCarCarModelIdentifier";
  /** model base code. Describes the model of the car. */
  code: Scalars["String"];
  /** pr numbers of the car. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model. */
  extensions?: Maybe<Array<Scalars["String"]>>;
  /** model version of the car. */
  version: Scalars["Int"];
  /** The year of the catalogue release. */
  year: Scalars["Int"];
};

/** A specific price. */
export type StockCarCarPrice = CarPrice & {
  __typename?: "StockCarCarPrice";
  /** Information about the currency */
  currencyDetails?: Maybe<Currency>;
  /** not supported yet */
  formattedValue?: Maybe<Scalars["String"]>;
  /** Price as float */
  value: Scalars["Float"];
  /** price as string */
  valueAsText?: Maybe<Scalars["String"]>;
};

/** All available prices for a car. */
export type StockCarCarPrices = CarPrices & {
  __typename?: "StockCarCarPrices";
  /** The total price of the car. */
  total?: Maybe<StockCarCarPrice>;
};

/** Carline information */
export type StockCarCarline = CarlineBase & {
  __typename?: "StockCarCarline";
  /** ID of the carline. F.ex. a4avant, a3sb, etc. */
  id: Scalars["String"];
  /** Localized name of the carline. F.ex. A4 Avant, A3 Sportback, etc. */
  name: Scalars["String"];
};

/** Model information */
export type StockCarModel = CarModelBase & {
  __typename?: "StockCarModel";
  /** Identifier of the model. */
  id: StockCarCarModelIdentifier;
  /** Name of the model */
  name?: Maybe<Scalars["String"]>;
};

/** Info Object of the Stock-Car subgraph */
export type StockcarGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: "StockcarGraphqlInfo";
  /** Endpoint of the Stock-Car subgraph */
  endpoint: Scalars["String"];
  /** Health status of the Stock-Car subgraph */
  health: OneGraphServiceHealth;
  /** id of the Stock-Car subgraph */
  id: Scalars["ID"];
  /** contact information of the Stock-Car subgraph */
  meta: OneGraphServiceMetaInfo;
  /** Name of the Stock-Car subgraph */
  name: Scalars["String"];
  /** Health status and information about the services which are used by Stock-Car subgraph */
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  /** Version of the Stock-Car subgraph */
  version: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar.techData`. Data provided by techdata-graphql subgraph. */
export type TechData = {
  __typename?: "TechData";
  acceleration?: Maybe<DataEntry>;
  displacement?: Maybe<DataEntry>;
  eec?: Maybe<Scalars["String"]>;
  electricRange?: Maybe<DataEntry>;
  fuelType?: Maybe<DataEntry>;
  gearbox?: Maybe<DataEntry>;
  hybridElectricalPower?: Maybe<DataEntry>;
  maxOutput?: Maybe<DataEntry>;
  maxTorque?: Maybe<DataEntry>;
  topSpeed?: Maybe<DataEntry>;
};

/** Complete technical data available for a given car configuration. */
export type TechnicalData = {
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: "9,5 s"
   */
  accelerationTime?: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType?: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes?: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume?: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch?: Maybe<TechnicalDataTextItem>;
  /** Energy Efficiency Certificate. */
  eec?: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * TODO: Should this be handled by a separate consumption & emission object. There might be more information regarding WLTP & NEFC
   * f.ex. 284,0 - 341,0 km
   */
  electricRangeCombined?: Maybe<TechnicalDataRangeItem>;
  /**
   * The engine for this vehicle
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: TechnicalDataEngineBase;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: "7-Gang S tronic"
   */
  gearbox?: Maybe<TechnicalDataTextItem>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard?: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Rims of the vehicle
   * f.ex. "Räder, 5-Arm-Design, 6 J x 15, Reifen 185/65 R 15"
   */
  rims?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad?: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * https://auto.schema.org/speed
   * f.ex. 250 km/h
   */
  speed?: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked?: Maybe<TechnicalDataFloatItem>;
  /**
   * Tyres of the vehicle
   * f.ex. "Reifen 185/65 R15 92 V xl"
   */
  tyres?: Maybe<TechnicalDataTextItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal?: Maybe<TechnicalDataFloatItem>;
};

/** Information about the battery for electro vehicles (also for hybrid with electro) */
export type TechnicalDataBattery = {
  __typename?: "TechnicalDataBattery";
  /** TODO name of battery capacity and its value need to be clearified */
  grossCapacity?: Maybe<TechnicalDataFloatItem>;
  /** TODO name of battery capacity and its value need to be clearified */
  netCapacity?: Maybe<TechnicalDataFloatItem>;
  /** TODO */
  type?: Maybe<TechnicalDataTextItem>;
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type TechnicalDataConfiguredCarInput = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars["String"];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars["String"];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions?: InputMaybe<Array<Scalars["String"]>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: InputMaybe<Scalars["String"]>;
  /** 2-digit pr number of the interior color */
  interiorColor?: InputMaybe<Scalars["String"]>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars["String"];
  /** the model input separte in an own input */
  model: TechnicalDataConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros. Check!
   */
  salesGroup?: InputMaybe<Scalars["String"]>;
};

export type TechnicalDataConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars["String"];
  /** 7-digit pr numbers */
  extensions?: InputMaybe<Array<Scalars["String"]>>;
  /** 1-digit model version */
  version: Scalars["Int"];
  /** The year of the catalogue release */
  year: Scalars["Int"];
};

/** Node for all data regarding consumption and emission values */
export type TechnicalDataConsumptionAndEmission = {
  __typename?: "TechnicalDataConsumptionAndEmission";
  /** Array of disctinct consumption and emission values for fuelType */
  consumptionAndEmissionPerFuel?: Maybe<
    Array<Maybe<TechnicalDataConsumptionAndEmissionPerFuel>>
  >;
  /** e.g. 40.5 mg/km */
  noxCombined?: Maybe<TechnicalDataFloatItem>;
  /** Summary values for footnotes etc. */
  summary?: Maybe<TechnicalDataConsumptionAndEmissionSummary>;
};

/** Per fuel consumption and emission values; separated by testing method */
export type TechnicalDataConsumptionAndEmissionPerFuel = {
  __typename?: "TechnicalDataConsumptionAndEmissionPerFuel";
  fuelType?: Maybe<Scalars["String"]>;
  /** Per fuel consumption and emission values for NEDC testing method */
  nedc?: Maybe<TechnicalDataConsumptionAndEmissionValuesNedc>;
  /**
   * Array of consumption and emission values per fuel for WLTP testing method; entries for different
   * energy management testing cycles
   */
  wltp?: Maybe<TechnicalDataConsumptionAndEmissionValuesWltp>;
};

/** Summary values for footnotes etc. */
export type TechnicalDataConsumptionAndEmissionSummary = {
  __typename?: "TechnicalDataConsumptionAndEmissionSummary";
  /** Summary consumption text for footnotes etc. */
  consumption?: Maybe<Scalars["String"]>;
  /** Summary consumption text for footnotes etc. */
  emissionCO2?: Maybe<Scalars["String"]>;
  /** Array of footnote references */
  footnoteReference?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Node containing consumption and emission values for NEDC testing method */
export type TechnicalDataConsumptionAndEmissionValuesNedc = {
  __typename?: "TechnicalDataConsumptionAndEmissionValuesNedc";
  /** Set of values for fuel consumption */
  consumption?: Maybe<ValuesNedc>;
  /** Set of values for CO2 emissions */
  emissionCO2?: Maybe<ValuesNedc>;
};

export type TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp = {
  __typename?: "TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp";
  /** Set of values for fuel consumption */
  consumption?: Maybe<ValuesWltp>;
  /** Set of values for CO2 emissions */
  emissionCO2?: Maybe<ValuesWltp>;
  /** applied measurement cycle */
  energyManagement?: Maybe<WltpEnergyManagement>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault?: Maybe<Scalars["Boolean"]>;
};

export type TechnicalDataConsumptionAndEmissionValuesWltp = {
  __typename?: "TechnicalDataConsumptionAndEmissionValuesWltp";
  consolidated?: Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>;
  perEnergyManagement?: Maybe<
    Array<
      Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>
    >
  >;
};

/** Electro engine */
export type TechnicalDataElectroEngine = TechnicalDataEngineBase & {
  __typename?: "TechnicalDataElectroEngine";
  /** Information about the battery */
  battery?: Maybe<TechnicalDataBattery>;
  /**
   * Charging durance 0 - 100 % with maximal charging power (AC).
   * f.ex. 11 kW
   */
  chargingDuranceAc?: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   */
  electricRangeCombined?: Maybe<TechnicalDataFloatItem>;
  /**
   * The power of the vehicle's engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * Maximal chargeable energy content.
   * f.ex. 19,7 kWh / XXX km
   */
  maxRangeChargingDc10?: Maybe<TechnicalDataFloatItem>;
  /** The maxinal hourly output of an electro engine */
  maximalHourlyPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Maximal loading power AC.
   * f.ex. 11 kW
   */
  maximalLoadingPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Minimal charging durance 5 - 80 % with maximal charging power (HPC).
   * f.ex. 36 min
   */
  minimalChargingDurance?: Maybe<TechnicalDataFloatItem>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque?: Maybe<TechnicalDataFloatItem>;
};

/**
 * The engine(s) for this vehicle.
 * (https://auto.schema.org/EngineSpecification)
 */
export type TechnicalDataEngineBase = {
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque?: Maybe<TechnicalDataFloatItem>;
};

/**
 * Describes a technical data attribute with a single float `value`.
 * Remark: Due to missing data in the downstream services we'll define the field `value` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we will use the field `valueAsText`
 */
export type TechnicalDataFloatItem = {
  __typename?: "TechnicalDataFloatItem";
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "193 km/h"
   */
  formattedValue: Scalars["String"];
  /**
   * The label of the value
   * f.ex. "Höchstgeschwindigkeit"
   */
  label?: Maybe<Scalars["String"]>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars["String"]>;
  /**
   * Unit of the value
   * f.ex "km/h"
   */
  unit?: Maybe<Scalars["String"]>;
  /**
   * Value as float
   * f.ex. 22134.12
   */
  value?: Maybe<Scalars["Float"]>;
  /**
   * Value as text (Sometimes the value is only available as text - in respect to the language)
   * f.ex "22134,12"
   */
  valueAsText?: Maybe<Scalars["String"]>;
};

/** Type for engines with fuel: petrol or diesel */
export type TechnicalDataFuelEngine = TechnicalDataEngineBase & {
  __typename?: "TechnicalDataFuelEngine";
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque?: Maybe<TechnicalDataFloatItem>;
};

/** Type for engines with gas */
export type TechnicalDataGasEngine = TechnicalDataEngineBase & {
  __typename?: "TechnicalDataGasEngine";
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * TODO: Would we handle the speed in rangesHow to handle mutliple fuelTypes
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque?: Maybe<TechnicalDataFloatItem>;
};

/** Electro engine */
export type TechnicalDataHybridEngine = TechnicalDataEngineBase & {
  __typename?: "TechnicalDataHybridEngine";
  /** Information about the battery */
  battery?: Maybe<TechnicalDataBattery>;
  /** City electric range */
  electricRangeCity?: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * f.ex. 284,0 - 341,0 km
   */
  electricRangeCombined?: Maybe<TechnicalDataFloatItem>;
  /**
   * Electrical Power
   * f.ex 94 kW
   */
  electricalPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The electrical torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  electricalTorque?: Maybe<TechnicalDataFloatItem>;
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The fuel power of the vehicle's hybrid engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /**
   * Momentary overall power
   * f.ex 280 kW
   */
  momentaryOverallPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /**
   * The total system torque (turning force) of the vehicle's engine (hybrid: fuel and electro combined)
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  totalSystemTorque?: Maybe<TechnicalDataFloatItem>;
};

/** All technical data belongs to insurance */
export type TechnicalDataInsuranceClass = {
  __typename?: "TechnicalDataInsuranceClass";
  /** Insurance class for fully comprehensive coverage. */
  fullyComprehensive?: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for basic liability. */
  liability?: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for partially comprehensive coverage. */
  partiallyComprehensive?: Maybe<TechnicalDataFloatItem>;
};

/**
 * Describes a technical data attribute with `min` and `max` value.
 * Remark: Due to missing data in the downstream services we'll define the fields `min/max` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we"ll use the field `minAsText/maxAsText`
 */
export type TechnicalDataRangeItem = {
  __typename?: "TechnicalDataRangeItem";
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "335/1090 l"
   */
  formattedValue: Scalars["String"];
  /**
   * The label of the value
   * f.ex. "Gepäckraumvolumen"
   */
  label?: Maybe<Scalars["String"]>;
  /**
   * Maximum value of the range
   * 120.44
   */
  max?: Maybe<Scalars["Float"]>;
  /**
   * Maximum value of the range as text.
   * f.ex. "120,44"
   */
  maxAsText?: Maybe<Scalars["String"]>;
  /**
   * Minimum value of the range
   * f.ex. 77.89
   */
  min?: Maybe<Scalars["Float"]>;
  /**
   * Minimum value of the range as text.
   * f.ex. "77,89"
   */
  minAsText?: Maybe<Scalars["String"]>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars["String"]>;
  /**
   * Unit of the value
   * f.ex. "l"
   */
  unit?: Maybe<Scalars["String"]>;
};

/** Describes a technical data attribute with a single `text`. */
export type TechnicalDataTextItem = {
  __typename?: "TechnicalDataTextItem";
  /**
   * The formattedValue of the technical data to display.
   * It contains the value directly f.ex. "Hydraulisch betätigte Trockenkupplung, asbest-/bleifreie Beläge"
   */
  formattedValue: Scalars["String"];
  /**
   * The label of the value
   * f.ex. "Kupplung"
   */
  label?: Maybe<Scalars["String"]>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars["String"]>;
};

/** TODO */
export type TedasCarCode = {
  country?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  language?: InputMaybe<Scalars["String"]>;
};

export type TedasServiceInfo = OneGraphSubServiceInfo & {
  __typename?: "TedasServiceInfo";
  endpoint: Scalars["String"];
  health: OneGraphServiceHealth;
  id: Scalars["ID"];
  meta: OneGraphServiceMetaInfo;
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars["String"];
};

/** @deprecated data moved into `ConfiguredCar` */
export type TyreLabel = {
  __typename?: "TyreLabel";
  productSheetUrl?: Maybe<Scalars["String"]>;
  tyreLabelUrl?: Maybe<Scalars["String"]>;
};

/** Full Carline Structure with all available Data */
export type UnfilteredCarlineStructure = {
  __typename?: "UnfilteredCarlineStructure";
  /** All Carline Attributes as list */
  carlineAttributes?: Maybe<Array<Maybe<CarlineAttribute>>>;
  /** All Carline Groups as list */
  carlineGroups: Array<CarlineGroup>;
  /** Structure mode */
  mode: CarlineStructureMode;
};

/** consumption or emission values for NEDC testing method */
export type ValuesNedc = {
  __typename?: "ValuesNedc";
  /** combined value for consumption or emission profile */
  combined?: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraUrban profile */
  extraUrban?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for urban profile */
  urban?: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for WLTP testing method */
export type ValuesWltp = {
  __typename?: "ValuesWltp";
  /** consumption or emission value for extraHigh profile */
  city?: Maybe<TechnicalDataFloatItem>;
  /** combined value for consumption or emission */
  combined?: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraHigh profile */
  extraHigh?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for high profile */
  high?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for low profile */
  low?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for medium profile */
  medium?: Maybe<TechnicalDataFloatItem>;
};

/** Defines a video by size, MIME type and source URL. */
export type VideoSource = {
  __typename?: "VideoSource";
  /** The height of the video in pixels, e.g. 1920. */
  height: Scalars["Int"];
  /** The MIME type of the video, e.g. "video/mp4". */
  mimeType: Scalars["String"];
  /** The source of the video, e.g. "https://www.mydomain.com/myvideo.mp4". */
  url: Scalars["String"];
  /** The width of the video in pixels, e.g. 1080. */
  width: Scalars["Int"];
};

/** Energy Management for WLTP measurement cycle */
export enum WltpEnergyManagement {
  /** PHEV with with depleted battery measurement */
  Depleting = "DEPLETING",
  /** Pure fuel measurement, applies for PEV or ICE */
  Pure = "PURE",
  /** MHEV or PHEV with with full battery measurement */
  Sustaining = "SUSTAINING",
  /** PHEV (unclear ?) measurement */
  Weighted = "WEIGHTED",
}

export type StatusQueryVariables = Exact<{ [key: string]: never }>;

export type StatusQuery = {
  __typename?: "Query";
  oneGraphInfo?: {
    __typename?: "OneGraphInfo";
    colaServiceInfo: {
      __typename?: "ColaServiceInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
    stockcarGraphqlInfo: {
      __typename?: "StockcarGraphqlInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
    audicodeGraphqlInfo: {
      __typename?: "AudicodeGraphqlInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
    carlineInfoServiceInfo: {
      __typename?: "CarlineInfoServiceInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
    financingGraphqlInfo: {
      __typename?: "FinancingGraphqlInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
    tedasServiceInfo: {
      __typename?: "TedasServiceInfo";
      health: {
        __typename?: "OneGraphServiceHealth";
        status: OneGraphServiceStatusEnum;
      };
    };
  } | null;
};
