import { Text } from "@audi/audi-ui-react";
import styled from "styled-components";

const {
  VITE_ONEGRAPH_VARIANT,
  MODE,
  VITE_STATUS_INT_URL,
  VITE_STATUS_PROD_URL,
} = import.meta.env;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
`;

export function Footer() {
  return (
    <StyledFooter>
      <Text as="p" variant="copy3" spaceStackStart="l">
        You are seeing the <b>{VITE_ONEGRAPH_VARIANT}</b> OneGraph environment,{" "}
        <a
          href={
            MODE === "production" ? VITE_STATUS_INT_URL : VITE_STATUS_PROD_URL
          }
        >
          switch to{" "}
          {VITE_ONEGRAPH_VARIANT === "production"
            ? "integration"
            : "production"}
        </a>
      </Text>
    </StyledFooter>
  );
}
