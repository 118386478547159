import { Text } from "@audi/audi-ui-react";
import styled from "styled-components";
import { StatusQueryOneGraphInfo } from "./App";
import { OneGraphServiceStatusEnum } from "./generated/onegraph";
import { statusToTexts } from "./utils";

const StyledStatusSummary = styled.div<{ status: OneGraphServiceStatusEnum }>`
  display: flex;
  box-sizing: border-box;
  border-radius: 2px;
  padding: ${({ theme }) => `var(${theme.responsive.spacing.m})`};
  background: ${({ theme, status }) => {
    switch (status) {
      case OneGraphServiceStatusEnum.Pass:
        return `var(${theme.colors.ui.success})`;
      case OneGraphServiceStatusEnum.Warn:
        return `var(${theme.colors.ui.pending})`;
      default:
        return `var(${theme.colors.ui.error})`;
    }
  }};
`;

const StyledStatusText = styled(Text)`
  color: ${({ theme }) => `var(${theme.colors.base.brand.white})`};
`;

interface StatusSummaryProps {
  oneGraphInfo: StatusQueryOneGraphInfo;
}

export function StatusSummary({ oneGraphInfo }: StatusSummaryProps) {
  let overallStatus = OneGraphServiceStatusEnum.Pass;

  for (const value of Object.values(oneGraphInfo)) {
    if (
      // skip value of __typename present on the oneGraphInfo object
      typeof value !== "string" &&
      value.health.status === OneGraphServiceStatusEnum.Fail
    ) {
      overallStatus = OneGraphServiceStatusEnum.Fail;
      break;
    }
  }

  return (
    <StyledStatusSummary status={overallStatus}>
      <StyledStatusText variant="order4" weight="bold">
        {statusToTexts[overallStatus].summary}
      </StyledStatusText>
    </StyledStatusSummary>
  );
}
