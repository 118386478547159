import React from "react";
import ReactDOM from "react-dom";
import { AudiPlatformProvider } from "@audi/audi-ui-react";
import { OneGraphProvider } from "@oneaudi/onegraph-client";
import { App } from "./App";
import { oneGraphClient } from "./onegraph";

ReactDOM.render(
  <React.StrictMode>
    <AudiPlatformProvider>
      <OneGraphProvider client={oneGraphClient}>
        <App />
      </OneGraphProvider>
    </AudiPlatformProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
