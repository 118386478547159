import { Loader, Text } from "@audi/audi-ui-react";
import styled from "styled-components";
import { gql, useQuery } from "@oneaudi/onegraph-client";
import Logo from "./Logo";
import { StatusSubGraphs } from "./StatusSubgraphs";
import { Footer } from "./Footer";
import { StatusSummary } from "./StatusSummary";
import { StatusQuery } from "./generated/onegraph";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `var(${theme.responsive.spacing.l})`};
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => `var(${theme.responsive.spacing.m})`};
  padding-top: ${({ theme }) => `var(${theme.responsive.spacing.m})`};
  box-sizing: border-box;
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
`;

const STATUS = gql`
  query Status {
    oneGraphInfo {
      colaServiceInfo {
        health {
          status
        }
      }
      stockcarGraphqlInfo {
        health {
          status
        }
      }
      audicodeGraphqlInfo {
        health {
          status
        }
      }
      carlineInfoServiceInfo {
        health {
          status
        }
      }
      financingGraphqlInfo {
        health {
          status
        }
      }
      tedasServiceInfo {
        health {
          status
        }
      }
    }
  }
`;

export type StatusQueryOneGraphInfo = NonNullable<StatusQuery["oneGraphInfo"]>;

export function App() {
  const { loading, data } = useQuery<StatusQuery>(STATUS);

  const oneGraphInfo = data?.oneGraphInfo;

  return (
    <StyledWrapper>
      <StyledHeader>
        <Logo />
        <Text as="h1" variant="order3">
          OneGraph Status
        </Text>
      </StyledHeader>

      <StyledMain>
        {!loading && oneGraphInfo ? (
          <>
            <StatusSummary oneGraphInfo={oneGraphInfo} />
            <StatusSubGraphs oneGraphInfo={oneGraphInfo} />
          </>
        ) : (
          <Loader />
        )}
      </StyledMain>

      <Footer />
    </StyledWrapper>
  );
}
